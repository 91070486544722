function initApp() {
  window.onload = function() {
    var modal = document.querySelector('.modal.is-active');
    var closeButton = document.querySelector('#delete-select');
    closeButton.addEventListener('click', function() {
      modal.remove();
    });
  }
}
document.addEventListener('DOMContentLoaded', initApp);
